@tailwind base;
@tailwind components;

@font-face {
  font-family: "Bahij TheSansArabic Light";
  src: local("Bahij TheSansArabic Light"),
    url(./fonts/TheSansArabic-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Arial";
  src: local("Arial"), url(./fonts/Arial.ttf) format("truetype");
}

@font-face {
  font-family: "Aldhabi";
  src: local("Aldhabi"), url(./fonts/Aldhabi.ttf) format("truetype");
}

@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* .new-font {
      font-family: ui-serif, Georgia, Cambria, "Aldhabi", Times, serif;
    } */
    .font-arabic {
      font-family: "Bahij TheSansArabic Light";
    }

    .h-80 {
      height: 80vh;
    }

    .min-h-80 {
      min-height: 80vh;
    }

    .h-90 {
      height: 90vh;
    }

    .max-width-1200 {
      max-width: 1200px;
    }
    .width-600 {
      width: 600px;
    }
    .h-screen-2 {
      min-height: 800px;
    }

    .h-100 {
      height: 100vh;
    }

    .h-125 {
      height: 125vh;
    }

    .h-150 {
      height: 150vh;
    }

    .w-90 {
      width: 90vh;
    }

    .w-100 {
      width: 100vh;
    }

    .w-125 {
      width: 125vh;
    }

    .w-150 {
      width: 150vh;
    }

    .h-new-48 {
      height: 48vh;
    }

    .min-h-50 {
      min-height: 50vh;
    }
    .min-h-60 {
      min-height: 50vh;
    }
    .text-gradient {
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .h-about-pics {
      height: 350px;
    }

    .w-about-pics {
      width: 350px;
    }

    .h-about-pics-xl {
      height: 550px;
    }

    .w-about-pics-xl {
      width: 550px;
    }

    .bg-reghda-blue {
      background-color: #91b6c6;
    }

    .bg-grey {
      background-color: #d3d3d3;
    }
    .text-reghda-blue {
      color: #91b6c6;
    }

    .bg-reghda-pink {
      background-color: #e7bdcb;
    }

    .text-reghda-pink {
      color: #e7bdcb;
    }

    .bg-reghda-black {
      background-color: #5b5b5b;
    }

    .text-reghda-black {
      color: #5b5b5b;
    }

    .border-reghda-black {
      border-color: #5b5b5b;
    }
    .border-reghda-pink {
      border-color: #e7bdcb;
    }

    .border-reghda-blue {
      border-color: #91b6c6;
    }

    .border-1 {
      border-width: 1px;
    }
    .border-2 {
      border-width: 2px;
    }

    .text-xxs {
      font-size: 0.5rem;
      line-height: 0.75rem;
    }
    .text-tiny {
      font-size: 0.25rem;
      line-height: 0.5rem;
    }

    .leading-raghda {
      line-height: 10;
    }

    .tracking-raghda {
      letter-spacing: 0.5em;
    }

    .text-8xl {
      font-size: 4rem;
    }

    .flex-70 {
      flex: 1 1 70%;
    }
    .flex-25 {
      flex: 1 1 25%;
    }
    .flex-30 {
      flex: 1 1 30%;
    }
    .flex-35 {
      flex: 1 1 35%;
    }
    .flex-32 {
      flex: 1 1 32%;
      max-width: 32%;
    }
    .flex-10 {
      flex: 1 1 10%;
    }
    .flex-45 {
      flex: 1 1 45%;
    }
    .flex-50 {
      flex: 1 1 50%;
    }
    .flex-75 {
      flex: 1 1 75%;
    }
    .flex-49 {
      flex: 1 1 49%;
      max-width: 49%;
    }
    .flex-100 {
      flex: 1 1 100%;
    }
    .box-shadow-custom {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .gap-5 {
      gap: 5%;
    }

    .gap-2 {
      gap: 2%;
    }

    /* Clamp */
    .large-clamp {
      font-size: clamp (18px, 5vw, 20px);
    }
    .base-clamp {
      font-size: clamp (14px, 3vw, 16px);
    }
    .border-bottom-1 {
      border-bottom: 1px solid;
    }
    .border-bottom-grey {
      border-bottom: 1px solid #d8d8d8;
    }
    .min-w-50 {
      min-width: 50px;
    }
    .min-w-200 {
      min-width: 200px;
    }
    .min-w-600 {
      min-width: 600px;
    }
    .width-60-100 {
      width: 60%;
    }
    .width-70-100 {
      width: 70%;
    }
    .width-90-100 {
      width: 90%;
    }
    .width-80-100 {
      width: 80%;
    }

    .max-width-50-100 {
      max-width: 50%;
    }
    .min-h-200 {
      min-height: 200px;
    }
    .rtl {
      direction: rtl;
    }
  }
}
