// @import url("https://fonts.googleapis.com/css2?family=Almarai&display=swap");

// html {
//   font-family: "Almarai", sans-serif;
// }

// @font-face {
//   font-family: "Aldhabi";
//   src: local("Aldhabi"), url(./../fonts/Aldhabi.ttf) format("truetype");
// }

// .new-font {
//   font-family: "Aldhabi", Fallback, serif;
// }
@import url("https://fonts.googleapis.com/css2?family=Almarai&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amiri:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Markazi+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lemonada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Scheherazade&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lateef&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Harmattan&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kufam&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mirza&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rakkas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Katibeh&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jomhuria&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vibes&display=swap");

.font-for-overview {
  font-family: "Aref Ruqaa", serif;
}

body {
  background-image: url(/public/images/first-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  // font-family: "Almarai", sans-serif;
}

.container {
  margin: 0 auto;
  height: auto;
}

.container-stretch {
  position: absolute;
  left: 0;
  right: 0;
}

.overview-sl {
  background-image: url("/public/images/overview.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.overview {
  background-image: url("/public/images/overview.png");
  background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: 100% 100%;
  background-size: cover;
  background-position: center;
}

.about-two-bg-shape {
  height: 100%;
  width: 100%;
  background-color: #c3dafe;
  clip-path: polygon(100% 0%, 100% 0%, 74% 49%, 100% 100%, 100% 100%);
}
.about-two-content {
  position: relative;
  bottom: 80vh;
}

.shapes {
  position: relative;
  left: 130px;
}

.waves {
  position: relative;
  right: 130px;
}

.waves-2 {
  position: relative;
  left: 17px;
}

.shapes-contact {
  position: relative;
  left: 60px;
  bottom: 55px;
}

.vector-contact {
  position: relative;
  right: 130px;
  bottom: 40px;
}

//cards

.card-container {
  max-width: 72rem;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 23fr;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 7rem;
  align-items: center;
  justify-items: center;
}

//nav

@media (max-width: 767px) {
  .nav__item {
    position: absolute;
    top: 54px;
    transition: all 0.5s ease;
    left: 0;
  }

  .hidden__nav {
    left: -100%;
  }
}

// people said

.psm {
  background-image: url(/public/images/people-said-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  top: 60px;
}

/* Show the dropdown menu on hover */
.dropdown .dropdownContent {
  display: block;
}

.picfit {
  object-fit: cover;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContentBlog {
  bottom: -50px;
}

/* Show the dropdown menu on hover */
.dropdownBlog .dropdownContentBlog {
  display: block;
}
